/* eslint-disable no-unused-vars */

import PageTitle from 'components/PageTitle';
import ErrorModal from 'components/Basic Modal/ErrorModal';
import React, { useState, } from 'react';
import { absoluteUrl } from 'utils/backendUrl';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from 'reactstrap';
import ParkingStationsConfiguration from 'components/Dialogs/ParkingStationsConfiguration';
import { useSelector } from 'react-redux';
import ParkingStationTable from 'components/ParkingStationModal/ParkingStationTable';

async function getParkingStations() {
    try {
        const parkingstations = await axios.get(absoluteUrl(`/parkingstations`))
        return parkingstations.data
    } catch (error) {
        console.log('GET__ERROR', error)
        return

    }
}

const ParkingStations = () => {
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [isConfigureParkingStationOpen, setIsConfigureParkingStationOpen] = useState(false)

    const closeConfigurationModal = () => {
        setIsConfigureParkingStationOpen(false)
    }
    const openConfigurationModal = () => {
        setIsConfigureParkingStationOpen(true)
    }


    const { data: allParkingStations, isLoading: isParkingStationsLoading } = useQuery({
        queryKey: ['allParkingStations'],
        queryFn: () => getParkingStations()
    })

    return (
        <>
            {/* Page Title With Icon */}
            <div className="card p-2 border border-light rounded w-50">
                <PageTitle title="Parking Stations" icon="/img/sidebar/tag.png" />
            </div>
            {/* Button for new add station */}
            <div className="d-flex justify-content-end ">
                <button
                    className="btn btn-primary "
                    onClick={openConfigurationModal}
                >
                    <i className="fa fa-plus-circle"></i>
                </button>
            </div>

            {/* Station all Tables */}
            {isParkingStationsLoading ? <Spinner size="sm" color="primary" /> :
                <ParkingStationTable
                    allParkingStations={allParkingStations}
                />}
            {/* Edit Or Add Station Modal*/}

            {/*Delete Station Modal */}

            {/* Error Modal */}
            <ErrorModal
                display={showErrorModal}
                size="lg"
                center="true"
                handleClose={() => {
                    setShowErrorModal(false);
                }}
                footer={true}
                content={
                    <>

                    </>
                }
            />

            {/* parking stations configuration */}
            <ParkingStationsConfiguration
                isConfigModalOpen={isConfigureParkingStationOpen}
                handleCloseConfigModal={closeConfigurationModal}
            />

        </>
    );
};

export default ParkingStations;